html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: overlay;
}

.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost ::-moz-scrollbar {
  display: none;
}

.scrollhost {
  overflow: overlay;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent; /*just hides the scrollbar for firefox */
}
